import { GeneraliGroupContent } from '@interfaces/generali-group.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const GENERALI_GROUP_RESPONSE_MOCK: Mockup = {
    success: [{
        response: {
            data: {
                titulo_intro: 'Generali es uno de los mayores proveedores mundiales de seguros y gestión de activos',
                descripcion_intro: '<p>Fundado en 1831, est&aacute; presente en&nbsp;<strong>m&aacute;s de 50 pa&iacute;ses del mundo</strong>, con unos ingresos totales por primas de&nbsp;<strong>82.500 millones de euros en 2023</strong>. Con&nbsp;<strong>casi 82.000 empleados</strong> que atienden a&nbsp;<strong>70 millones de clientes</strong>, el Grupo tiene una posici&oacute;n de liderazgo en Europa y una presencia creciente en Asia y Am&eacute;rica Latina.</p>\n<p><strong><a title=\"Grupo Generali\" href=\"https://www.generali.com/\" target=\"_blank\" rel=\"noopener\">Grupo Generali</a>&nbsp;</strong>ha integrado plenamente la sostenibilidad de manera estrat&eacute;gica, con el objetivo de crear valor para sus p&uacute;blicos de inter&eacute;s y al mismo tiempo construir una sociedad m&aacute;s justa y resiliente.</p>',
                titulo_cifras: 'De un vistazo',
                subtitulo_cifras: 'Revisa las cifras al 31 de diciembre de 2023',
                descripcion_cifras: '<p>En los &uacute;ltimos a&ntilde;os, hemos pasado por una <strong><a href=\"https://www.generali.com/who-we-are/our-management\" target=\"_blank\" rel=\"noopener\">reorganizaci&oacute;n estrat&eacute;gica</a></strong> que nos ha permitido centrarnos m&aacute;s en nuestro negocio principal, una mayor disciplina en la gesti&oacute;n de nuestros activos y una gobernanza m&aacute;s simple y transparente. Este proceso nos ha llevado a una nueva etapa, basada en modelos de negocio m&aacute;s eficientes, estrategias comerciales innovadoras y una marca m&aacute;s fuerte y global.</p>',
                titulo_negocio: 'Nuestro negocio responsable',
                descripcion_negocio: '<p>En Generali, todo lo que hacemos se basa en la noci&oacute;n de que la verdadera prosperidad econ&oacute;mica s&oacute;lo puede lograrse si se construye sobre bases socialmente justas y ambientalmente s&oacute;lidas. Es por eso que la&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-business\" target=\"_blank\" rel=\"nofollow noopener\">sostenibilidad</a></strong><strong>&nbsp;es el origen de nuestra&nbsp;<a href=\"https://www.generali.com/who-we-are/Strategic-Plan-Lifetime-Partner-24\" target=\"_blank\" rel=\"nofollow noopener\">estrategia</a></strong>: significa fomentar el desempe&ntilde;o financiero teniendo en cuenta a las personas y al planeta, actuando por el bien com&uacute;n para construir una sociedad m&aacute;s resiliente y justa. En resumen, se trata de vivir nuestro&nbsp;<strong><a href=\"https://www.generali.com/who-we-are/our-culture\" target=\"_blank\" rel=\"nofollow noopener\">prop&oacute;sito</a></strong>: &ldquo;<strong>permitir que las personas forjen un futuro m&aacute;s seguro y sostenible cuidando sus vidas y sus sue&ntilde;os</strong>&rdquo;. Para crear valor sostenible a largo plazo, identificamos&nbsp;<strong>cuatro roles responsables</strong> que desempe&ntilde;ar como inversionista, asegurador, empleador y ciudadano corporativo.</p>',
                subtitulo_negocio: 'La sostenibilidad es el origen de nuestra estrategia',
                titulo_plan: 'El plan estratégico "Lifetime Partner 24: Impulsando el crecimiento"',
                link_video: 'https://www.youtube.com/embed/1U-XHjY_bSI?si=Rydxr6wPZtVVP9B-',
                descripcion_plan: '<p>El plan estrat&eacute;gico&nbsp;<strong><a href=\"https://www.generali.com/who-we-are/Strategic-Plan-Lifetime-Partner-24\" target=\"_blank\" rel=\"nofollow noopener\">Lifetime Partner 24: Impulsando el crecimiento</a></strong>&nbsp;establece una visi&oacute;n clara para Generali en 2024 y se basa en&nbsp;<strong>tres pilares estrat&eacute;gicos</strong>: impulsar el crecimiento sostenible, mejorar el perfil de ganancias y liderar la innovaci&oacute;n.</p>\n<p>La base del plan es nuestro compromiso continuo de ser un socio vitalicio para nuestros 70 millones de clientes en m&aacute;s de 50 pa&iacute;ses. Las inversiones en productos personalizados, asesoramiento digital y la mejor experiencia para el cliente permitir&aacute;n que Generali y su red global de 164.000 agentes se acerquen a&uacute;n m&aacute;s a los clientes.</p>\n<p>Iremos m&aacute;s all&aacute; en nuestros&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-business\" target=\"_blank\" rel=\"nofollow noopener\">compromisos de sostenibilidad</a></strong> durante este ciclo estrat&eacute;gico, con un enfoque continuo en generar un impacto social, ambiental positivo en nuestros grupos de inter&eacute;s. Adem&aacute;s, continuaremos invirtiendo en nuestra gente para asegurarnos de que est&eacute;n comprometidos con la ejecuci&oacute;n exitosa del nuevo plan y al mismo tiempo fomentemos un ambiente de trabajo sostenible.</p>',
                cifras: [
                    {
                        tarjetas_cifras_id: {
                            alt_image: 'Icono Tablet',
                            title_image: 'Icono Tablet',
                            texto_superior: '6.9 bln',
                            texto_medio: '+ 7.9%',
                            texto_inferior: 'Resultados Operacionales',
                            icono: {
                                filename_disk: 'ff777eed-031c-4b1c-8ddd-2b38e5330ee3.svg'
                            }
                        }
                    },
                    {
                        tarjetas_cifras_id: {
                            alt_image: 'Icono Mundo',
                            title_image: 'Icono Mundo',
                            texto_superior: 'Más de',
                            texto_medio: '50 países',
                            texto_inferior: 'Presencia Mundial',
                            icono: {
                                filename_disk: 'ed604793-09cb-4277-a3e7-cb93c53236f8.svg'
                            }
                        }
                    },
                    {
                        tarjetas_cifras_id: {
                            alt_image: 'Icono Manos',
                            title_image: 'Icono Manos',
                            texto_superior: 'Casi',
                            texto_medio: '82 mil',
                            texto_inferior: 'Colaboradores',
                            icono: {
                                filename_disk: '00d7c3cb-1ea7-48bf-8267-7ef37d998ca5.svg'
                            }
                        }
                    }
                ],
                roles: [
                    {
                        tarjetas_negocio_responsable_id: {
                            alt_image: 'Inversor Responsable',
                            title_image: 'Inversor Responsable',
                            titulo: 'Inversor Responsable',
                            descripcion: '<p>Nuestro objetivo es integrar plenamente los&nbsp;<strong>criterios ESG</strong>&nbsp;en nuestras opciones de inversi&oacute;n,&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-investor/sustainability-into-investments/investment-portfolio-decarbonisation\" target=\"_blank\" rel=\"nofollow noopener\">reducir las emisiones de gases de efecto invernadero</a></strong>&nbsp;de la cartera de inversiones a cero emisiones netas para 2050, y&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-investor/sustainability-into-investments\" target=\"_blank\" rel=\"nofollow noopener\">aumentar nuestras nuevas inversiones verdes y sostenibles</a></strong>, incluidas las inversiones&nbsp;<strong>Fenice 190</strong> para apoyar la recuperaci&oacute;n de la UE.</p>',
                            icono: {
                                filename_disk: '035d7845-7d90-46a9-8fe4-a2f3b63cff1b.svg'
                            }
                        }
                    },
                    {
                        tarjetas_negocio_responsable_id: {
                            alt_image: 'Asegurador Responsable',
                            title_image: 'Asegurador Responsable',
                            titulo: 'Asegurador Responsable',
                            descripcion: '<p>Brindamos&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-insurer/insurance-solutions-with-ESG-components\" target=\"_blank\" rel=\"nofollow noopener\">soluciones de seguros con componentes ESG</a></strong>, reduciendo las emisiones de gases de efecto invernadero de la cartera de suscripci&oacute;n a&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-investor/investing-for-the-climate\" target=\"_blank\" rel=\"nofollow noopener\">cero emisiones netas para 2050</a></strong>&nbsp;y apoyando la transici&oacute;n sostenible de las peque&ntilde;as y medianas empresas (PYME) a trav&eacute;s del&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-insurer/SME-EnterPRIZE\" target=\"_blank\" rel=\"nofollow noopener\">proyecto SME EnterPRIZE</a></strong>.</p>',
                            icono: {
                                filename_disk: '4c2bf244-5319-440b-8e15-d47b670a4748.svg'
                            }
                        }
                    },
                    {
                        tarjetas_negocio_responsable_id: {
                            alt_image: 'Empleador Responsable',
                            title_image: 'Empleador Responsable',
                            titulo: 'Empleador Responsable',
                            descripcion: '<p>En el marco de nuestra&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-employer/Generali-people-strategy\" target=\"_blank\" rel=\"nofollow noopener\">Estrategia de Personas</a></strong>, promovemos la&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-employer/diversity-and-inclusion\" target=\"_blank\" rel=\"nofollow noopener\">diversidad, la equidad y la inclusi&oacute;n</a></strong>,&nbsp;<strong><a href=\"https://www.generali.com/work-with-us/grow-with-us/Your-development\" target=\"_blank\" rel=\"nofollow noopener\">mejorando las habilidades de nuestra gente</a></strong>, fomentando el talento e implementando&nbsp;<strong>formas de trabajo sostenibles</strong>. Nuestro&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-employer/greenhouse-gas-emissions\" target=\"_blank\" rel=\"nofollow noopener\">compromiso con el medio ambiente y el clima</a></strong> incluye medir, reducir y reportar la huella de carbono resultante de nuestras propias operaciones directas.</p>',
                            icono: {
                                filename_disk: 'c2e37d51-adf2-43ff-b8c6-909f5a841dcf.svg'
                            }
                        }
                    },
                    {
                        tarjetas_negocio_responsable_id: {
                            alt_image: 'Ciudadano Responsable',
                            title_image: 'Ciudadano Responsable',
                            titulo: 'Ciudadano Corporativo Responsable',
                            descripcion: '<p>Actuamos para liberar el potencial de las personas que viven en circunstancias vulnerables a trav&eacute;s de las iniciativas globales de&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-citizen/the-human-safety-net\" target=\"_blank\" rel=\"nofollow noopener\">The Human Safety Net Foundation</a></strong>, un centro de innovaci&oacute;n social impulsado por las habilidades y la red internacional de Generali.</p>',
                            icono: {
                                filename_disk: '8e07793e-5032-4845-91c8-018a6e3ef57d.svg'
                            }
                        }
                    }
                ]
            }
        }
    }],
    failures: []
};

export const GENERALI_GROUP_CONTENT_MOCKUP: GeneraliGroupContent = {
    header: {
        image: {
            url: 'https://cms-qa.planvital.cl/assets/ad5905d6-caf4-4e7f-a48a-5c50725a1834.webp',
            alt: 'Grupo Generali',
            title: 'Grupo Generali'
        },
        image_mobile: {
            url: 'https://cms-qa.planvital.cl/assets/ad5905d6-caf4-4e7f-a48a-5c50725a1834.webp',
            alt: 'Grupo Generali',
            title: 'Grupo Generali'
        },
        firstLine: 'Grupo Generali'
    },
    content: {
        introTitle: 'Generali es uno de los mayores proveedores mundiales de seguros y gestión de activos',
        introDescription: '<p>Fundado en 1831, est&aacute; presente en&nbsp;<strong>m&aacute;s de 50 pa&iacute;ses del mundo</strong>, con unos ingresos totales por primas de&nbsp;<strong>82.500 millones de euros en 2023</strong>. Con&nbsp;<strong>casi 82.000 empleados</strong> que atienden a&nbsp;<strong>70 millones de clientes</strong>, el Grupo tiene una posici&oacute;n de liderazgo en Europa y una presencia creciente en Asia y Am&eacute;rica Latina.</p>\n<p><strong><a title=\"Grupo Generali\" href=\"https://www.generali.com/\" target=\"_blank\" rel=\"noopener\">Grupo Generali</a>&nbsp;</strong>ha integrado plenamente la sostenibilidad de manera estrat&eacute;gica, con el objetivo de crear valor para sus p&uacute;blicos de inter&eacute;s y al mismo tiempo construir una sociedad m&aacute;s justa y resiliente.</p>',
        ammountsTitle: 'De un vistazo',
        ammountsSubtitle: 'Revisa las cifras al 31 de diciembre de 2023',
        ammountsDescription: '<p>En los &uacute;ltimos a&ntilde;os, hemos pasado por una <strong><a href=\"https://www.generali.com/who-we-are/our-management\" target=\"_blank\" rel=\"noopener\">reorganizaci&oacute;n estrat&eacute;gica</a></strong> que nos ha permitido centrarnos m&aacute;s en nuestro negocio principal, una mayor disciplina en la gesti&oacute;n de nuestros activos y una gobernanza m&aacute;s simple y transparente. Este proceso nos ha llevado a una nueva etapa, basada en modelos de negocio m&aacute;s eficientes, estrategias comerciales innovadoras y una marca m&aacute;s fuerte y global.</p>',
        businessTitle: 'Nuestro negocio responsable',
        businessSubtitle: 'La sostenibilidad es el origen de nuestra estrategia',
        businessDescription: '<p>En Generali, todo lo que hacemos se basa en la noci&oacute;n de que la verdadera prosperidad econ&oacute;mica s&oacute;lo puede lograrse si se construye sobre bases socialmente justas y ambientalmente s&oacute;lidas. Es por eso que la&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-business\" target=\"_blank\" rel=\"nofollow noopener\">sostenibilidad</a></strong><strong>&nbsp;es el origen de nuestra&nbsp;<a href=\"https://www.generali.com/who-we-are/Strategic-Plan-Lifetime-Partner-24\" target=\"_blank\" rel=\"nofollow noopener\">estrategia</a></strong>: significa fomentar el desempe&ntilde;o financiero teniendo en cuenta a las personas y al planeta, actuando por el bien com&uacute;n para construir una sociedad m&aacute;s resiliente y justa. En resumen, se trata de vivir nuestro&nbsp;<strong><a href=\"https://www.generali.com/who-we-are/our-culture\" target=\"_blank\" rel=\"nofollow noopener\">prop&oacute;sito</a></strong>: &ldquo;<strong>permitir que las personas forjen un futuro m&aacute;s seguro y sostenible cuidando sus vidas y sus sue&ntilde;os</strong>&rdquo;. Para crear valor sostenible a largo plazo, identificamos&nbsp;<strong>cuatro roles responsables</strong> que desempe&ntilde;ar como inversionista, asegurador, empleador y ciudadano corporativo.</p>',
        planTitle: 'El plan estratégico "Lifetime Partner 24: Impulsando el crecimiento"',
        videoLink: 'https://www.youtube.com/embed/1U-XHjY_bSI?si=Rydxr6wPZtVVP9B-',
        planDescription: '<p>El plan estrat&eacute;gico&nbsp;<strong><a href=\"https://www.generali.com/who-we-are/Strategic-Plan-Lifetime-Partner-24\" target=\"_blank\" rel=\"nofollow noopener\">Lifetime Partner 24: Impulsando el crecimiento</a></strong>&nbsp;establece una visi&oacute;n clara para Generali en 2024 y se basa en&nbsp;<strong>tres pilares estrat&eacute;gicos</strong>: impulsar el crecimiento sostenible, mejorar el perfil de ganancias y liderar la innovaci&oacute;n.</p>\n<p>La base del plan es nuestro compromiso continuo de ser un socio vitalicio para nuestros 70 millones de clientes en m&aacute;s de 50 pa&iacute;ses. Las inversiones en productos personalizados, asesoramiento digital y la mejor experiencia para el cliente permitir&aacute;n que Generali y su red global de 164.000 agentes se acerquen a&uacute;n m&aacute;s a los clientes.</p>\n<p>Iremos m&aacute;s all&aacute; en nuestros&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-business\" target=\"_blank\" rel=\"nofollow noopener\">compromisos de sostenibilidad</a></strong> durante este ciclo estrat&eacute;gico, con un enfoque continuo en generar un impacto social, ambiental positivo en nuestros grupos de inter&eacute;s. Adem&aacute;s, continuaremos invirtiendo en nuestra gente para asegurarnos de que est&eacute;n comprometidos con la ejecuci&oacute;n exitosa del nuevo plan y al mismo tiempo fomentemos un ambiente de trabajo sostenible.</p>',
        ammounts: [
            {
                altImage: 'Icono Tablet',
                titleImage: 'Icono Tablet',
                upperText: '6.9 bln',
                middleText: '+ 7.9%',
                lowerText: 'Resultados Operacionales',
                icon: 'https://cms-qa.planvital.cl/assets/ff777eed-031c-4b1c-8ddd-2b38e5330ee3.svg'
            },
            {
                altImage: 'Icono Mundo',
                titleImage: 'Icono Mundo',
                upperText: 'Más de',
                middleText: '50 países',
                lowerText: 'Presencia Mundial',
                icon: 'https://cms-qa.planvital.cl/assets/ed604793-09cb-4277-a3e7-cb93c53236f8.svg'
            },
            {
                altImage: 'Icono Manos',
                titleImage: 'Icono Manos',
                upperText: 'Casi',
                middleText: '82 mil',
                lowerText: 'Colaboradores',
                icon: 'https://cms-qa.planvital.cl/assets/00d7c3cb-1ea7-48bf-8267-7ef37d998ca5.svg'
            }
        ],
        roles: [
            {
                altImage: 'Inversor Responsable',
                titleImage: 'Inversor Responsable',
                title: 'Inversor Responsable',
                description: '<p>Nuestro objetivo es integrar plenamente los&nbsp;<strong>criterios ESG</strong>&nbsp;en nuestras opciones de inversi&oacute;n,&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-investor/sustainability-into-investments/investment-portfolio-decarbonisation\" target=\"_blank\" rel=\"nofollow noopener\">reducir las emisiones de gases de efecto invernadero</a></strong>&nbsp;de la cartera de inversiones a cero emisiones netas para 2050, y&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-investor/sustainability-into-investments\" target=\"_blank\" rel=\"nofollow noopener\">aumentar nuestras nuevas inversiones verdes y sostenibles</a></strong>, incluidas las inversiones&nbsp;<strong>Fenice 190</strong> para apoyar la recuperaci&oacute;n de la UE.</p>',
                icon: 'https://cms-qa.planvital.cl/assets/035d7845-7d90-46a9-8fe4-a2f3b63cff1b.svg'
            },
            {
                altImage: 'Asegurador Responsable',
                titleImage: 'Asegurador Responsable',
                title: 'Asegurador Responsable',
                description: '<p>Brindamos&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-insurer/insurance-solutions-with-ESG-components\" target=\"_blank\" rel=\"nofollow noopener\">soluciones de seguros con componentes ESG</a></strong>, reduciendo las emisiones de gases de efecto invernadero de la cartera de suscripci&oacute;n a&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-investor/investing-for-the-climate\" target=\"_blank\" rel=\"nofollow noopener\">cero emisiones netas para 2050</a></strong>&nbsp;y apoyando la transici&oacute;n sostenible de las peque&ntilde;as y medianas empresas (PYME) a trav&eacute;s del&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-insurer/SME-EnterPRIZE\" target=\"_blank\" rel=\"nofollow noopener\">proyecto SME EnterPRIZE</a></strong>.</p>',
                icon: 'https://cms-qa.planvital.cl/assets/4c2bf244-5319-440b-8e15-d47b670a4748.svg'
            },
            {
                altImage: 'Empleador Responsable',
                titleImage: 'Empleador Responsable',
                title: 'Empleador Responsable',
                description: '<p>En el marco de nuestra&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-employer/Generali-people-strategy\" target=\"_blank\" rel=\"nofollow noopener\">Estrategia de Personas</a></strong>, promovemos la&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-employer/diversity-and-inclusion\" target=\"_blank\" rel=\"nofollow noopener\">diversidad, la equidad y la inclusi&oacute;n</a></strong>,&nbsp;<strong><a href=\"https://www.generali.com/work-with-us/grow-with-us/Your-development\" target=\"_blank\" rel=\"nofollow noopener\">mejorando las habilidades de nuestra gente</a></strong>, fomentando el talento e implementando&nbsp;<strong>formas de trabajo sostenibles</strong>. Nuestro&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-employer/greenhouse-gas-emissions\" target=\"_blank\" rel=\"nofollow noopener\">compromiso con el medio ambiente y el clima</a></strong> incluye medir, reducir y reportar la huella de carbono resultante de nuestras propias operaciones directas.</p>',
                icon: 'https://cms-qa.planvital.cl/assets/c2e37d51-adf2-43ff-b8c6-909f5a841dcf.svg'
            },
            {
                altImage: 'Ciudadano Responsable',
                titleImage: 'Ciudadano Responsable',
                title: 'Ciudadano Corporativo Responsable',
                description: '<p>Actuamos para liberar el potencial de las personas que viven en circunstancias vulnerables a trav&eacute;s de las iniciativas globales de&nbsp;<strong><a href=\"https://www.generali.com/sustainability/responsible-citizen/the-human-safety-net\" target=\"_blank\" rel=\"nofollow noopener\">The Human Safety Net Foundation</a></strong>, un centro de innovaci&oacute;n social impulsado por las habilidades y la red internacional de Generali.</p>',
                icon: 'https://cms-qa.planvital.cl/assets/8e07793e-5032-4845-91c8-018a6e3ef57d.svg'
            }
        ]
    }
};
