import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';
import { MandatorySavings } from '@interfaces/savings.interface';

// tslint:disable:max-line-length
export const GET_MANDATORY_SAVINGS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: {
          filename_disk: `97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
        },
        imagen_banner_mobile: {
          filename_disk: `97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
        },
        traducciones: [
          {
            consejos: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
            languages_code: 'es',
          }
        ],
        carrusel: [
          {
            infografias_id: 1
          },
          {
            infografias_id: 2
          },
          {
            infografias_id: 3
          }
        ],
        alt_image: '',
        title_image: '',
        boton_carrusel: 'Cámbiate a AFP PLANVITAL',
        descripcion_cards: '<p>Si perteneces a una de estas categor&iacute;as de trabajador, te corresponde cotizar de forma obligatoria y puedes ser parte de AFP PlanVital</p>',
        descripcion_carrusel: 'Ahorra con AFP PlanVital para tu futura pensión.',
        mostrar_imagen_card: true,
        texto_app: '<p>Si est&aacute;s afiliado a AFP PlanVital&nbsp;<strong>revisa tus ahorros y haz tus tr&aacute;mites desde nuestra App.</strong></p>',
        texto_boton_app: 'Descarga la App AFP PlanVital',
        titulo_cards: '¿Para quienes esta dirigido el ahorro obligatorio?',
        titulo_carrusel: 'Beneficios de tu ahorro obligatorio',
        titulo_disclaimer: 'Consejos para tu cuenta de Ahorro Obligatorio',
        tarjetas: [
            {
                tarjetas_imagenes_id: {
                    titulo: 'Afiliado Dependiente',
                    descripcion: '<p>Si tienes un Contrato de Trabajo y recibes una remuneraci&oacute;n por parte de tu empleador.</p>',
                    alt_image: 'Afiliado Dependiente',
                    title_image: 'Afiliado Dependiente',
                    imagen: {
                        filename_disk: '0cffd5ed-92a5-4495-82e2-412f2fb07664.webp'
                    }
                }
            },
            {
                tarjetas_imagenes_id: {
                    titulo: 'Afiliado Independiente',
                    descripcion: '<p>Si emites boletas de honorarios por un monto bruto anual igual o mayor a 5 Ingresos M&iacute;nimos Mensuales en el a&ntilde;o.</p>',
                    alt_image: 'Afiliado Independiente',
                    title_image: 'Afiliado Independiente',
                    imagen: {
                        filename_disk: '8aaaeb3b-e110-417c-badf-c991c2df2224.webp'
                    }
                }
            },
            {
                tarjetas_imagenes_id: {
                    titulo: 'Afiliado Voluntario',
                    descripcion: '<p>Si no ejerces una actividad remunerada o est&aacute;s recibiendo el pago de una pensi&oacute;n en cualquier Sistema Previsional</p>',
                    alt_image: 'Afiliado Voluntario',
                    title_image: 'Afiliado Voluntario',
                    imagen: {
                        filename_disk: '81ea4d90-6260-46c3-b094-3f635ff2aece.webp'
                    }
                }
            }
        ]        
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

const pageKey = 'AFFILIATE.SAVE_WITH_US.MANDATORY_SAVINGS';

export const MANDATORY_SAVINGS_MOCKUP: MandatorySavings = {
  header: {
    image: {
      url: `${environment.cmsApiUrl}assets/97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
      alt: '',
      title: '',
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
      alt: '',
      title: '',
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  carousel: {
    title: 'Beneficios de tu ahorro obligatorio',
    subtitle: 'Ahorra con AFP PlanVital para tu futura pensión.',
    button: 'Cámbiate a AFP PLANVITAL',
    benefits: [
      {
        id: 1,
        icon: 'lupa',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 2,
        icon: 'client',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 3,
        icon: 'support',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      }
    ],
  },
  savingCardsSection: {
    title: '¿Para quienes esta dirigido el ahorro obligatorio?',
    subtitle: '<p>Si perteneces a una de estas categor&iacute;as de trabajador, te corresponde cotizar de forma obligatoria y puedes ser parte de AFP PlanVital</p>',
    cards: [
      {
        image: {
          url: `${environment.cmsApiUrl}assets/0cffd5ed-92a5-4495-82e2-412f2fb07664.webp`,
          alt: 'Afiliado Dependiente',
          title: 'Afiliado Dependiente'
        },
        title: 'Afiliado Dependiente',
        description: '<p>Si tienes un Contrato de Trabajo y recibes una remuneraci&oacute;n por parte de tu empleador.</p>',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.MODAL_DESCRIPTION`,
          image: {
            url: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.IMAGE`,
            alt: 'Dependiente'
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: `${environment.cmsApiUrl}assets/8aaaeb3b-e110-417c-badf-c991c2df2224.webp`,
          alt: 'Afiliado Independiente',
          title: 'Afiliado Independiente'
        },
        title: 'Afiliado Independiente',
        description: '<p>Si emites boletas de honorarios por un monto bruto anual igual o mayor a 5 Ingresos M&iacute;nimos Mensuales en el a&ntilde;o.</p>',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: `${environment.cmsApiUrl}assets/81ea4d90-6260-46c3-b094-3f635ff2aece.webp`,
          alt: 'Afiliado Voluntario',
          title: 'Afiliado Voluntario'
        },
        title: 'Afiliado Voluntario',
        description: '<p>Si no ejerces una actividad remunerada o est&aacute;s recibiendo el pago de una pensi&oacute;n en cualquier Sistema Previsional</p>',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  adviceSection: {
    title: 'Consejos para tu cuenta de Ahorro Obligatorio',
    body: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
    disclaimer: '<p>Si est&aacute;s afiliado a AFP PlanVital&nbsp;<strong>revisa tus ahorros y haz tus tr&aacute;mites desde nuestra App.</strong></p>',
    disclaimerButton: 'Descarga la App AFP PlanVital',
    disclaimerUrl: 'download_app',
    showCardImage: true,
  }
};

export const GET_MANDATORY_SAVINGS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: null,
        imagen_banner_mobile: null,
        traducciones: [
          {
            consejos: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
            languages_code: 'es'
          }
        ],
        carrusel: [],
        tarjetas: [],
        alt_image: null,
        title_image: null,
        boton_carrusel: null,
        descripcion_cards: null,
        descripcion_carrusel: null,
        mostrar_imagen_card: true,
        texto_app: null,
        texto_boton_app: null,
        titulo_cards: null,
        titulo_carrusel: null,
        titulo_disclaimer: null,
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const MANDATORY_SAVINGS_NULL_MOCKUP: MandatorySavings = {
  header: {
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  carousel: {
    title: null,
    subtitle: null,
    button: null,
    benefits: [],
  },
  savingCardsSection: {
    title: null,
    subtitle: null,
    cards: [
      {
        image: {
          url: `${environment.cmsApiUrl}assets/0cffd5ed-92a5-4495-82e2-412f2fb07664.webp`,
          alt: 'Afiliado Dependiente',
          title: 'Afiliado Dependiente'
        },
        title: 'Afiliado Dependiente',
        description: '<p>Si tienes un Contrato de Trabajo y recibes una remuneraci&oacute;n por parte de tu empleador.</p>',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.MODAL_DESCRIPTION`,
          image: {
            url: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.IMAGE`,
            alt: 'Dependiente'
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: `${environment.cmsApiUrl}assets/8aaaeb3b-e110-417c-badf-c991c2df2224.webp`,
          alt: 'Afiliado Independiente',
          title: 'Afiliado Independiente'
        },
        title: 'Afiliado Independiente',
        description: '<p>Si emites boletas de honorarios por un monto bruto anual igual o mayor a 5 Ingresos M&iacute;nimos Mensuales en el a&ntilde;o.</p>',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: `${environment.cmsApiUrl}assets/81ea4d90-6260-46c3-b094-3f635ff2aece.webp`,
          alt: 'Afiliado Voluntario',
          title: 'Afiliado Voluntario'
        },
        title: 'Afiliado Voluntario',
        description: '<p>Si no ejerces una actividad remunerada o est&aacute;s recibiendo el pago de una pensi&oacute;n en cualquier Sistema Previsional</p>',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  adviceSection: {
    title: null,
    body: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: 'download_app',
    showCardImage: true
  }
};
