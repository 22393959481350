import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';
import { VoluntarySavings } from '@interfaces/savings.interface';

// tslint:disable:max-line-length
export const GET_VOLUNTARY_SAVINGS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        apv_preguntas_traducciones: [{
          preguntas: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar<br /></strong>En nuestro sitio o en nuestra red de sucursales.</li>\n<li><strong>&iquest;Cu&aacute;l es el monto m&aacute;ximo a retirar?</strong><br />Puedes retirar cualquier monto o el total del saldo ahorrado en la cuenta</li>\n<li><strong>Si quiero hacer un retiro, &iquest;Cu&aacute;les son las formas de pago?</strong><br />Dep&oacute;sito en la cuenta bancaria. Cheque en sucursal.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />El 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud. Y en Dep&oacute;sito en Cuenta Bancaria, el 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud.</li>\n</ul>',
          languages_code: 'es',
        }],
        imagen_banner: {
          filename_disk: `f172d1a6-8674-4ab1-9365-2c042674db32.png`,
        },
        imagen_banner_mobile: {
          filename_disk: `f172d1a6-8674-4ab1-9365-2c042674db32.png`,
        },
        carrusel: [{
          infografias_id: 1
        },
        {
          infografias_id: 2
        },
        {
          infografias_id: 3
        }],
        alt_image: null,
        title_image: null,
        boton_carrusel: 'Test',
        descripcion_cards: 'Test',
        descripcion_carrusel: 'Test',
        mostrar_imagen_card: true,
        titulo_cards: 'Test',
        titulo_carrusel: 'Test',
        titulo_disclaimer: 'Test',
        titulo_comision: 'Test',
        descripcion_comision: 'Test',
        tarjetas: [
            {
                tarjetas_imagenes_id: {
                    titulo: 'Test',
                    descripcion: 'Test',
                    alt_image: 'Test',
                    title_image: 'Test',
                    imagen: {
                        filename_disk: 'test.webp'
                    }
                }
            },
            {
                tarjetas_imagenes_id: {
                    titulo: 'Test',
                    descripcion: 'Test',
                    alt_image: 'Test',
                    title_image: 'Test',
                    imagen: {
                        filename_disk: 'test.webp'
                    }
                }
            },
            {
                tarjetas_imagenes_id: {
                    titulo: 'Test',
                    descripcion: 'Test',
                    alt_image: 'Test',
                    title_image: 'Test',
                    imagen: {
                        filename_disk: 'test.webp'
                    }
                }
            }
        ]        
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

const pageKey = 'AFFILIATE.SAVE_WITH_US.VOLUNTARY_SAVINGS';

export const VOLUNTARY_SAVINGS_MOCKUP: VoluntarySavings = {
  header: {
    image: {
      url: `${environment.cmsApiUrl}assets/f172d1a6-8674-4ab1-9365-2c042674db32.png`,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/f172d1a6-8674-4ab1-9365-2c042674db32.png`,
      alt: null,
      title: null,
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  button: 'Test',
  showCardImage: true,
  carousel: {
    title: 'Test',
    subtitle: 'Test',
    benefits: [
      {
        id: 1,
        icon: 'lupa',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 2,
        icon: 'client',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 3,
        icon: 'support',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      }
    ],
  },
  savingCardsSection: {
    title: 'Test',
    subtitle: 'Test',
    cards: [
      {
        image: {
          url: `${environment.cmsApiUrl}assets/test.webp`,
          alt: 'Test',
          title: 'Test'
        },
        title: 'Test',
        description: 'Test',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: `${environment.cmsApiUrl}assets/test.webp`,
          alt: 'Test',
          title: 'Test',
        },
        title: 'Test',
        description: 'Test',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.APV.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.APV.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: true,
        redirectButton: {
          redirectURL: 'apv',
          textButton: `${pageKey}.CARDS.CHARACTERISTICS.APV.BUTTON_TEXT`,
        }
      },
      {
        image: {
          url: `${environment.cmsApiUrl}assets/test.webp`,
          alt: 'Test',
          title: 'Test'
        },
        title: 'Test',
        description: 'Test',
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  questionsSection: {
    title: 'Test',
    body: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar<br /></strong>En nuestro sitio o en nuestra red de sucursales.</li>\n<li><strong>&iquest;Cu&aacute;l es el monto m&aacute;ximo a retirar?</strong><br />Puedes retirar cualquier monto o el total del saldo ahorrado en la cuenta</li>\n<li><strong>Si quiero hacer un retiro, &iquest;Cu&aacute;les son las formas de pago?</strong><br />Dep&oacute;sito en la cuenta bancaria. Cheque en sucursal.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />El 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud. Y en Dep&oacute;sito en Cuenta Bancaria, el 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud.</li>\n</ul>',
    disclaimer: `${pageKey}.QUESTIONS.DISCLAIMER`,
    disclaimerButton: `${pageKey}.QUESTIONS.BUTTON`,
    disclaimerUrl: 'url',
  },
  commissionDisclaimer: {
    title: 'Test',
    description: 'Test'
  }
};

export const GET_VOLUNTARY_SAVINGS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: null,
        imagen_banner_mobile: null,
        apv_preguntas_traducciones: [
          {
            preguntas: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar<br /></strong>En nuestro sitio o en nuestra red de sucursales.</li>\n<li><strong>&iquest;Cu&aacute;l es el monto m&aacute;ximo a retirar?</strong><br />Puedes retirar cualquier monto o el total del saldo ahorrado en la cuenta</li>\n<li><strong>Si quiero hacer un retiro, &iquest;Cu&aacute;les son las formas de pago?</strong><br />Dep&oacute;sito en la cuenta bancaria. Cheque en sucursal.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />El 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud. Y en Dep&oacute;sito en Cuenta Bancaria, el 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud.</li>\n</ul>',
            languages_code: 'es',
          }
        ],
        carrusel: [],
        tarjetas: [],
        alt_image: null,
        title_image: null,
        boton_carrusel: null,
        descripcion_cards: null,
        descripcion_carrusel: null,
        mostrar_imagen_card: true,
        titulo_cards: null,
        titulo_carrusel: null,
        titulo_disclaimer: null,
        titulo_comision: null,
        descripcion_comision: null,
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const VOLUNTARY_SAVINGS_NULL_MOCKUP: VoluntarySavings = {
  header: {
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  showCardImage: true,
  button: null,
  carousel: {
    title: null,
    subtitle: null,
    benefits: [],
  },
  savingCardsSection: {
    title: null,
    subtitle: null,
    cards: [
      {
        image: {
          url: 'assets/images/AFP_Planvital_apv_cotizaciones_voluntarias.jpg',
          alt: 'Cotizaciones voluntarias',
          title: 'Cotizaciones voluntarias'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_apv_abre_un_apv.jpg',
          alt: 'Abre un APV',
          title: 'Abre un APV'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.APV.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.APV.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.APV.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.APV.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: true,
        redirectButton: {
          redirectURL: 'apv',
          textButton: `${pageKey}.CARDS.CHARACTERISTICS.APV.BUTTON_TEXT`,
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_apv_depositos_colectivos.jpg',
          alt: 'Depósitos colectivos',
          title: 'Depósitos colectivos'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  questionsSection: {
    title: null,
    body: '<ul>\n<li><strong>&iquest;D&oacute;nde lo puedo solicitar<br /></strong>En nuestro sitio o en nuestra red de sucursales.</li>\n<li><strong>&iquest;Cu&aacute;l es el monto m&aacute;ximo a retirar?</strong><br />Puedes retirar cualquier monto o el total del saldo ahorrado en la cuenta</li>\n<li><strong>Si quiero hacer un retiro, &iquest;Cu&aacute;les son las formas de pago?</strong><br />Dep&oacute;sito en la cuenta bancaria. Cheque en sucursal.</li>\n<li><strong>&iquest;Qu&eacute; Valor Cuota se utiliza para el c&aacute;lculo del giro?</strong><br />El del d&iacute;a h&aacute;bil anteprecedente a la fecha de disponibilidad del retiro.</li>\n<li><strong>&iquest;Cu&aacute;ndo est&aacute;n disponibles los Fondos?</strong><br />El 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud. Y en Dep&oacute;sito en Cuenta Bancaria, el 6&ordm; d&iacute;a h&aacute;bil siguiente a la solicitud.</li>\n</ul>',
    disclaimer: `${pageKey}.QUESTIONS.DISCLAIMER`,
    disclaimerButton: `${pageKey}.QUESTIONS.BUTTON`,
    disclaimerUrl: 'url',
  },
  commissionDisclaimer: {
    title: null,
    description: null
  }
};
