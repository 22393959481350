import { animate, style, transition, trigger } from '@angular/animations';




export const VitaliaAnimations = [trigger('inOutVitalia', 
    [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(30px)' }),
        animate('600ms 800ms', style({ opacity: 1, transform: 'translateX(0)'})),
      ]),
      transition(':leave', [
        animate('200ms 300ms', style({ opacity: 0, transform: 'translateX(10px)' })),
      ]),
    ]
  ),
  trigger('inOutWelcome', 
    [
      transition(':enter', [
        style({ opacity: 0, right: 0 }),
        animate('600ms 1500ms', style({ opacity: 1, right: '143px'})),
      ]),
      transition(':leave', [
        animate('200ms 300ms', style({ opacity: 0, transform: 'translateX(10px)' })),
      ]),
    ]
  ),
  trigger('inOutDisplayBtn', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(30px)' }),
      animate( '600ms 500ms', style({ opacity: 1, transform: 'translateX(0px)' })),
    ]),
    transition(':leave', [
      animate('600ms 500ms', style({ opacity: 0, transform: 'translateX(10px)' })),
    ]),
  ])
];
