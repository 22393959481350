import { Mockup } from '@interfaces/mockup.interface';

export const GET_PROFITABILITY_PERCETAGES: Mockup = {
    success: [{
        response: {
            data: {
                id: 1,
                status: 'published',
                fondo_a_1_month: '2,3',
                fondo_a_1_year: '22,6',
                fondo_a_3_year: '19,0',
                fondo_a_5_year: '50,4',
                fondo_b_1_month: '0,8',
                fondo_b_1_year: '21,2',
                fondo_b_3_year: '24,1',
                fondo_b_5_year: '48,9',
                fondo_c_1_month: '-1,9',
                fondo_c_1_year: '18,6',
                fondo_c_3_year: '26,4',
                fondo_c_5_year: '36,8',
                fondo_d_1_month: '-4,5',
                fondo_d_1_year: '18,3',
                fondo_d_3_year: '35,4',
                fondo_d_5_year: '31,5',
                fondo_e_1_month: '-5,4',
                fondo_e_1_year: '19,4',
                fondo_e_3_year: '44,8',
                fondo_e_5_year: '32,7',
                fechas_mes: 'Variación del valor cuota entre el 30-09-2024 y el 31-10-2024',
                fechas_year: 'Variación del valor cuota entre el 31-10-2023 y el 31-10-2024',
                fechas_three_years: 'Variación del valor cuota entre el 31-10-2021 y el 31-10-2024',
                fechas_five_years: 'Variación del valor cuota entre el 31-10-2019 y el 31-10-2024',
                disclaimer: '<p><em>Valores Cuota: Publicados por la Superintendencia de Pensiones</em></p>\n<p>*Fuente: ' +
                'Superintendencia de Pensiones. Rentabilidad real de los Fondos de Pensiones, periodo octubre de 2024. ' +
                'La rentabilidad es variable, por lo que nada garantiza que las rentabilidades pasadas se repitan en el futuro. ' +
                'Inf&oacute;rmate sobre la rentabilidad de tu Fondo de Pensiones, las comisiones y la calidad de servicio de las ' +
                'AFP en el sitio web de la Superintendencia de Pensiones ' +
                '<a href=\"https://www.spensiones.cl\" target=\"_blank\" rel=\"noopener\">www.spensiones.cl</a></p>',
            }
        }
    }],
    failures: []
};
